import React, { useEffect } from "react";
import Startup from "../../components/startup/Startup";
import Services from "../../components/services/Services";
import RequestForm from "../../components/request-form/RequestForm";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import GalleryCard from "../../components/gallery-card/GalleryCard";
import { useDispatch } from "react-redux";
import { ShowProfileAction } from "../../redux/actions/ProfileAction";
import { GetServicesAction } from "../../redux/actions/ServicesAction";

const Home = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(ShowProfileAction());
    dispatch(GetServicesAction());
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <Startup />
      <Services />
      <GalleryCard />
      <RequestForm />
      <Footer />
    </>
  );
};

export default Home;
