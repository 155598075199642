import React, { useContext, useState } from "react";
import { translations } from "../translate";
import { LangContext } from "../../../context/LanguageProvider";
import { langType } from "../../../utils/Formatters";

const Step2 = ({ updateFormData, formData, previousStep, nextStep }) => {
  const { lang } = useContext(LangContext);
  const translatedData = translations[lang];

  const [localFormData, setLocalFormData] = useState({
    businessName: formData.businessName,
    businessType: formData.businessType,
    socialMediaImages: formData.socialMediaImages || [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalFormData({ ...localFormData, [name]: value });
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 10) {
      alert("You can only upload a maximum of 10 images");
      setLocalFormData({ ...localFormData, socialMediaImages: [] });
      return
    }
    setLocalFormData({ ...localFormData, socialMediaImages: files });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateFormData(localFormData);
    nextStep();
  };

  return (
    <form onSubmit={handleSubmit} className="step-form">
      <div>
        <label>{translatedData.businessName}</label>
        <input
          className={`${langType(localFormData.businessName[0])}`}
          type="text"
          name="businessName"
          value={localFormData.businessName}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label>{translatedData.businessType}</label>
        <select
          name="businessType"
          value={localFormData.businessType}
          onChange={handleChange}
          required
        >
          <option value="">{translatedData.select}</option>
          <option value={`1-${translatedData.restaurant}`}>{translatedData.restaurant}</option>
          <option value={`2-${translatedData.medicalCenter}`}>{translatedData.medicalCenter}</option>
          <option value={`3-${translatedData.newCompany}`}>{translatedData.newCompany}</option>
          <option value={`4-${translatedData.product}`}>{translatedData.product}</option>
          <option value={`5-${translatedData.other}`}>{translatedData.other}</option>
        </select>
      </div>
      <div>
        <label>{translatedData.socialMediaImages}</label>
        <input
          type="file"
          name="socialMediaImages"
          accept="image/*"
          multiple
          onChange={handleFileChange}
        />
      </div>
      <div className="actions">
        <button type="submit">{translatedData.next}</button>
        <button type="button" onClick={previousStep}>
          {translatedData.previous}
        </button>
      </div>
    </form>
  );
};

export default Step2;
