import React, { useContext } from "react";
import "./Footer.scss";
import { Carousel } from "react-responsive-carousel";
import {
  FaFacebook,
  FaInstagram,
  FaPhone,
  FaTiktok,
  FaMapMarkerAlt,
} from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { formatNumberWithAbbreviations } from "../../utils/Formatters";
import { translations } from "./tramslate";
import { LangContext } from "../../context/LanguageProvider";
import { imageURL } from "../../redux/Api";
import { useSelector } from "react-redux";

const Footer = () => {
  const { lang } = useContext(LangContext);
  const translatedData = translations[lang];
  const { profileInfo, profileImages } = useSelector((state) => state.profile);

  if (profileInfo.length === 0 || profileImages.length === 0) {
    return null;
  }

  return (
    <footer className="footer" id="about-me">
      <div className="profile-card">
        <Carousel
          autoFocus
          showThumbs={false}
          showIndicators={false}
          showStatus={false}
          showArrows={false}
          stopOnHover={false}
          infiniteLoop
          transitionTime={300}
          autoPlay
          axis="horizontal"
        >
          {profileImages.map((image, index) => (
            <img
              key={index}
              src={imageURL + image.path}
              alt={`profile-${index}`}
            />
          ))}
        </Carousel>
        <div className="content">
          <h1>{profileInfo.name}</h1>
          <p className="bio">{profileInfo.bio}</p>

          <h2>{translatedData.instagramInfo.title}</h2>
          <div className="instagram-info">
            <div className="border">
              <div className="number">{translatedData.instagramInfo.posts}</div>
              <span>
                {formatNumberWithAbbreviations(profileInfo.count_posts)}
              </span>
            </div>
            <div className="border">
              <div className="number">
                {translatedData.instagramInfo.followers}
              </div>
              <span>
                {formatNumberWithAbbreviations(profileInfo.count_followers)}
              </span>
            </div>
            <div className="border">
              <div className="number">
                {translatedData.instagramInfo.following}
              </div>
              <span>
                {formatNumberWithAbbreviations(profileInfo.count_following)}
              </span>
            </div>
          </div>

          <div className="label">
            <FaMapMarkerAlt className="icon" />
            <span>{profileInfo.location}</span>
          </div>
          <div className="label">
            <FaPhone className="icon phone" />
            <span>{profileInfo.phone_number}</span>
          </div>

          <div className="social-links">
            <a
              href={profileInfo.facebook}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook className="icon" />
            </a>
            <a
              href={profileInfo.instagram}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram className="icon" />
            </a>
            <a
              href={profileInfo.tiktok}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTiktok className="icon" />
            </a>
            <a
              href={`mailto:${profileInfo.email}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IoMail className="icon" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
