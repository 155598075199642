import { createSlice } from '@reduxjs/toolkit';
import { PostRequestAction } from '../actions/RequestAction';

export const requestReducer = createSlice({
    name: "Request Reducer",
    initialState: {
        loading: false,
    },
    reducers: {
    },

    extraReducers: (builder) => {
        builder
            .addCase(PostRequestAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(PostRequestAction.fulfilled, (state, { payload }) => {
                state.loading = false;
            })
            .addCase(PostRequestAction.rejected, (state) => {
                state.loading = false;
            })
    }
});

export default requestReducer.reducer;