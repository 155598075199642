import React from "react";
import "./Anchor.scss";
import { Link } from "react-router-dom";

const Anchor = ({ href, label, to }) => {
  if (href)
    return (
      <a href={`#${href}`} className="cta">
        <span>{label}</span>
      </a>
    );
  else
    return (
      <Link to={to} className="cta">
        <span>{label}</span>
      </Link>
    );
};

export default Anchor;
