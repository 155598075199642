import { createContext, useEffect, useState } from "react";

export const LangContext = createContext();

export const LanguageProvider = ({ children }) => {
    const [lang, setLang] = useState(localStorage.getItem('lang') || 'en');

    useEffect(() => {
        localStorage.setItem('lang', lang);
    }, [lang]);

    const toggle = () => {
        setLang((prev) => (prev === "en" ? "ar" : "en"));
        window.location.reload();
    };

    return (
        <LangContext.Provider value={{ toggle, lang }}>
            <div className={`${lang}`}>{children}</div>
        </LangContext.Provider>
    );
};
