export const translations = {
    en: {
        reviewInformation: "Review Your Information",
        fullName: "Full Name:",
        email: "Email:",
        whatsappNumber: "Whatsapp Number:",
        location: "Location:",
        businessName: "Business Name:",
        businessType: "Business Type:",
        description: "Description:",
        preferredADDate: "Preferred AD Date:",
        socialMediaImages: "Social Media Images:",
        submit: "Submit",
        previous: "Previous",
        next: "Next",
        select: "Select",
        restaurant: "Restaurant",
        medicalCenter: "Medical Center",
        newCompany: "New Company",
        product: "Product",
        other: "Other",
        stepNames: {
            contact: 'Contact',
            business: 'Business',
            description: 'Description',
            date: 'Date',
            request: 'Request',
        }
    },
    ar: {
        reviewInformation: "مراجعة معلوماتك",
        fullName: "الاسم الكامل:",
        email: "البريد الإلكتروني:",
        whatsappNumber: "رقم الواتساب:",
        location: "العنوان:",
        businessName: "اسم العمل:",
        businessType: "نوع العمل:",
        description: "الوصف:",
        preferredADDate: "التاريخ المفضل للإعلان:",
        socialMediaImages: "صور وسائل التواصل الاجتماعي:",
        submit: "تقديم",
        previous: "السابق",
        next: "التالي",
        select: "اختر",
        restaurant: "مطعم",
        medicalCenter: "مركز طبي",
        newCompany: "شركة جديدة",
        product: "منتج",
        other: "آخر",
        stepNames: {
            contact: 'تواصل',
            business: 'العمل',
            description: 'الوصف',
            date: 'التاريخ',
            request: 'الطلب',
        }
    },
};
