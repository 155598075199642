import React, { useContext } from "react";
import Anchor from "../anchor/Anchor";
import "./GalleryCard.scss";
import { translations } from "./translate";
import { LangContext } from "../../context/LanguageProvider";

const GalleryCard = () => {
  const { lang } = useContext(LangContext);
  const translatedData = translations[lang];

  return (
    <div className="gallery-card" id="gallery-card">
      <div className="card">
        <img
          src={require("../../assets/images/gallery-image.jpg")}
          alt="Cover"
        />
        <div className="card-content">
          <h2>{translatedData.title}</h2>
          <p>{translatedData.description}</p>
          <Anchor to="/gallery" label={translatedData.viewGallery} />
        </div>
      </div>
    </div>
  );
};

export default GalleryCard;
