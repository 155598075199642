export const translations = {
    en: {
        title: "My Works",
        description:
            "Explore my portfolio of projects and collaborations including modeling requests, acting collaborations, paid advertisements, and marketing your business",
        viewGallery: "View Gallery",
    },
    ar: {
        title: "أعمالي",
        description:
            "استكشف مجموعتي من المشاريع والتعاونات بما في ذلك طلبات التصوير، والتعاونات التمثيلية، والإعلانات المدفوعة، وتسويق عملك",
        viewGallery: "عرض المعرض",
    },
};
