import React, { useContext, useState } from "react";
import "./RequestForm.scss";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import Step4 from "./steps/Step4";
import StepWizard from "react-step-wizard";
import Result from "./steps/Result";
import { Stepper } from "react-form-stepper";
import { LangContext } from "../../context/LanguageProvider";
import { translations } from "./translate";

const RequestForm = () => {
  const { lang } = useContext(LangContext);
  const translatedData = translations[lang];
  const [activeStep, setActiveStep] = useState(1);
  const stepHandler = (props) => {
    setActiveStep(props.activeStep);
  };
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    address: "",
    businessName: "",
    businessType: "",
    socialMediaImages: "",
    description: "",
    adDate: "",
  });

  const updateFormData = (newData) => {
    setFormData((prevData) => ({ ...prevData, ...newData }));
  };

  return (
    <div id="service-request" className="request-form">
      <div className="request-container">
        <Stepper
          steps={[
            { label: translatedData.stepNames.contact },
            { label: translatedData.stepNames.business },
            { label: translatedData.stepNames.description },
            { label: translatedData.stepNames.date },
            { label: translatedData.stepNames.request },
          ]}
          activeStep={activeStep - 1}
          className="custom-stepper"
          stepClassName="custom-step"
        />

        <StepWizard
          initialStep={activeStep}
          onStepChange={stepHandler}
          className="step-wizard"
        >
          <Step1 updateFormData={updateFormData} formData={formData} />
          <Step2 updateFormData={updateFormData} formData={formData} />
          <Step3 updateFormData={updateFormData} formData={formData} />
          <Step4 updateFormData={updateFormData} formData={formData} />
          <Result formData={formData} />
        </StepWizard>
      </div>
    </div>
  );
};

export default RequestForm;
