import React, { useContext } from "react";
import { translations } from "../translate";
import { LangContext } from "../../../context/LanguageProvider";
import { useDispatch } from "react-redux";
import { PostRequestAction } from "../../../redux/actions/RequestAction";
import { unwrapResult } from "@reduxjs/toolkit";

const Result = ({ formData, previousStep }) => {
  const { lang } = useContext(LangContext);
  const translatedData = translations[lang];
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    let info = new FormData();
    info.append("full name", formData.fullName);
    info.append("email", formData.email);
    info.append("whatsapp_number", formData.phone);
    info.append("address", formData.address);
    info.append("business_name", formData.businessName);
    info.append("business_type", formData.businessType.split("-")[0]);
    info.append("description", formData.description);
    info.append("ad date", formData.adDate);

    for (let i = 0; i < formData.socialMediaImages.length; i++) {
      info.append(`images[${i}]`, formData.socialMediaImages[i]);
    }

    dispatch(PostRequestAction(info))
      .then(unwrapResult)
      .then((payload) => {
        alert(payload.message);
        window.location.reload();
      })
      .catch((err) => {
        alert(err);
      });
  };
  return (
    <div onSubmit={handleSubmit} className="step-form">
      <div className="result">
        <h2>{translatedData.reviewInformation}</h2>
        <div>
          <strong>{translatedData.fullName}</strong> {formData.fullName}
        </div>
        <div>
          <strong>{translatedData.email}</strong> {formData.email}
        </div>
        <div>
          <strong>{translatedData.whatsappNumber}</strong> {formData.phone}
        </div>
        <div>
          <strong>{translatedData.location}</strong> {formData.address}
        </div>
        <div>
          <strong>{translatedData.businessName}</strong> {formData.businessName}
        </div>
        <div>
          <strong>{translatedData.businessType}</strong>
          {formData.businessType.split("-")[1]}
        </div>
        <div>
          <strong>{translatedData.description}</strong> {formData.description}
        </div>
        <div>
          <strong>{translatedData.preferredADDate}</strong> {formData.adDate}
        </div>

        <strong>{translatedData.socialMediaImages}</strong>
        <div className="images">
          {formData.socialMediaImages.length !== 0 ? (
            formData.socialMediaImages.map((file, index) => (
              <img
                key={index}
                src={URL.createObjectURL(file)}
                alt={file.name}
              />
            ))
          ) : (
            <h3 style={{ color: "crimson" }}>No Images Selected !</h3>
          )}
        </div>
      </div>
      <div className="actions">
        <button onClick={handleSubmit}>{translatedData.submit}</button>
        <button type="button" onClick={previousStep}>
          {translatedData.previous}
        </button>
      </div>
    </div>
  );
};

export default Result;
