export const translations = {
    en: {
        title: "Mastering the Art of Persuasive Advertising",
        getStarted: "Get Started",
        expert: "Expert in Targeted Advertising.",
        results: "Delivering Results-Driven Ad Solutions.",
    },
    ar: {
        title: "إتقان فن الإعلان",
        getStarted: "ابدأ الآن",
        expert: "خبيرة في الإعلانات المستهدفة.",
        results: "تقديم حلول إعلانية موجهة بالنتائج.",
    },
};
