import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LanguageProvider } from "./context/LanguageProvider";
import Home from "./pages/home/Home";
import Gallery from "./pages/gallery/Gallery";
import LoadingFS from "./components/loading/LoadingFS";

function App() {
  return (
    <BrowserRouter>
      <LoadingFS />
      <LanguageProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Home />} />
          <Route path="/gallery" element={<Gallery />} />
        </Routes>
      </LanguageProvider>
    </BrowserRouter>
  );
}

export default App;
