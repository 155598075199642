import React, { useContext } from "react";
import Anchor from "../anchor/Anchor";
import "./Startup.scss";
import { translations } from "./translate";
import { LangContext } from "../../context/LanguageProvider";
import { useSelector } from "react-redux";
import { imageURL } from "../../redux/Api";

const Startup = () => {
  const { lang } = useContext(LangContext);
  const translatedData = translations[lang];
  const { profileInfo } = useSelector((state) => state.profile);

  return (
    <div className="startup" id="home">
      <div className="banner">
        <h1>{translatedData.title}</h1>
        <div className="flex-row">
          <Anchor href={"service-request"} label={translatedData.getStarted} />
          <div className="flex-column">
            <p>{translatedData.expert}</p>
            <p>{translatedData.results}</p>
          </div>
        </div>
      </div>
      {profileInfo?.image?.path && (
        <div className="image">
          <img src={imageURL + profileInfo.image.path} alt="" />
        </div>
      )}
    </div>
  );
};

export default Startup;
