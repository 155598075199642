export const translations = {
    en: {
        instagramInfo: {
            title: "Instagram Information",
            posts: "Posts",
            followers: "Followers",
            following: "Following",
        },
    },
    ar: {
        instagramInfo: {
            title: "معلومات إنستغرام",
            posts: "المنشورات",
            followers: "يتابعه",
            following: "يتابع",
        },
    },
};
