import React, { useContext, useEffect, useState } from "react";
import "./Gallery.scss";
import Anchor from "../../components/anchor/Anchor";
import {
  GetPostsAction,
  ShowPostAction,
} from "../../redux/actions/GalleryAction";
import { useDispatch, useSelector } from "react-redux";
import { imageURL } from "../../redux/Api";
import { unwrapResult } from "@reduxjs/toolkit";
import { LangContext } from "../../context/LanguageProvider";

const Gallery = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isClosing, setIsClosing] = useState(false);
  const { gallery } = useSelector((state) => state.gallery);
  const dispatch = useDispatch();
  const { lang } = useContext(LangContext);

  const fetchVideoSource = async (id) => {
    try {
      const result = await dispatch(ShowPostAction(id));
      const data = unwrapResult(result);
      return imageURL + data.data.video.path;
    } catch (err) {
      return null;
    }
  };

  const handleCardClick = async (video) => {
    const src = await fetchVideoSource(video.id);
    if (src) {
      setSelectedVideo(src);
    }
  };

  const closeFullscreen = () => {
    setIsClosing(true);
    setTimeout(() => {
      setSelectedVideo(null);
      setIsClosing(false);
    }, 500);
  };

  const handleTouchMove = (e) => {
    closeFullscreen();
  };

  useEffect(() => {
    dispatch(GetPostsAction());
  }, [dispatch]);

  return (
    <div className="gallery">
      <div className="nav">
        <img src="/logo.png" alt="zayna-barakat" />
        <span className="header">Zayna Gallery</span>
        <Anchor to="/" label={`${lang === "en" ? "Go Back" : "العودة"}`} />
      </div>
      {gallery.length === 0 && (
        <div className="empty-card">
          <h2>
            {lang === "en"
              ? "No Available Videos Until Now"
              : "لا توجد مقاطع فيديو متاحة حتى الآن"}
          </h2>
        </div>
      )}
      <div className="gallery-container">
        {gallery.map((video) => (
          <div
            className="video-card"
            key={video.id}
            onClick={() => handleCardClick(video)}
          >
            <video
              controls={false}
              poster={imageURL + video?.image?.path}
            ></video>
            <h3>{video.title}</h3>
          </div>
        ))}

        {selectedVideo && (
          <div className={`fullscreen-video ${isClosing ? "closing" : ""}`}>
            <div
              className="video-container"
              onClick={(e) => e.stopPropagation()}
            >
              <button className="close-button" onClick={closeFullscreen}>
                &times;
              </button>
              <video
                playsInline
                preload="metadata"
                controls
                autoPlay
                onTouchMove={handleTouchMove}
              >
                <source src={selectedVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Gallery;
