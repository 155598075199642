import React, { useContext, useState } from "react";
import { LangContext } from "../../context/LanguageProvider";
import { translations } from "./translate";
import "./Navbar.scss";

const Navbar = () => {
  const [isActive, setIsActive] = useState(false);
  const { toggle, lang } = useContext(LangContext);
  const translatedData = translations[lang];

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  return (
    <nav className="navbar">
      <img src="./logo.png" alt="" />
      <div>
        <div className={`menu ${isActive ? "active" : ""}`}>
          <div className="header">
            <img className="logo" src="./logo.png" alt="" />
            <div className="close" onClick={toggleMenu}>
              {lang === "en" ? "Close" : "إغلاق"}
            </div>
          </div>
          <div className="elements">
            <a
              onClick={() => {
                toggleMenu();
                toggle();
              }}
              href="#home"
            >
              {translatedData[0]}
            </a>
            <a onClick={toggleMenu} href="#home">
              {translatedData[1]}
            </a>
            <a onClick={toggleMenu} href="#services">
              {translatedData[2]}
            </a>
            <a onClick={toggleMenu} href="#gallery-card">
              {translatedData[3]}
            </a>
            <a onClick={toggleMenu} href="#service-request">
              {translatedData[4]}
            </a>
            <a onClick={toggleMenu} href="#about-me">
              {translatedData[5]}
            </a>
          </div>
        </div>
        <div
          className={`menu__toggler ${isActive ? "active" : ""}`}
          onClick={toggleMenu}
        >
          <span></span>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
