import React, { useContext, useState } from "react";
import { translations } from "../translate";
import { LangContext } from "../../../context/LanguageProvider";
import { langType } from "../../../utils/Formatters";

const Step3 = ({ updateFormData, formData, previousStep, nextStep }) => {
  const { lang } = useContext(LangContext);
  const translatedData = translations[lang];

  const [localFormData, setLocalFormData] = useState({
    description: formData.description,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalFormData({ ...localFormData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateFormData(localFormData);
    nextStep();
  };

  return (
    <form onSubmit={handleSubmit} className="step-form">
      <div>
        <label>{translatedData.description}</label>
        <textarea
          className={`${langType(localFormData.description[0])}`}
          rows={3}
          name="description"
          value={localFormData.description}
          onChange={handleChange}
          required
        ></textarea>
      </div>
      <div className="actions">
        <button type="submit">{translatedData.next}</button>
        <button type="button" onClick={previousStep}>
          {translatedData.previous}
        </button>
      </div>
    </form>
  );
};

export default Step3;
