import React, { useContext, useState } from "react";
import { translations } from "../translate";
import { LangContext } from "../../../context/LanguageProvider";

const Step4 = ({ updateFormData, formData, previousStep, nextStep }) => {
  const { lang } = useContext(LangContext);
  const translatedData = translations[lang];

  const [localFormData, setLocalFormData] = useState({
    adDate: formData.adDate,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalFormData({ ...localFormData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateFormData(localFormData);
    nextStep();
  };

  return (
    <form onSubmit={handleSubmit} className="step-form">
      <div>
        <label>{translatedData.preferredADDate}</label>
        <input
          type="date"
          name="adDate"
          value={localFormData.adDate}
          onChange={handleChange}
          required
        />
      </div>
      <div className="actions">
        <button type="submit">{translatedData.next}</button>
        <button type="button" onClick={previousStep}>
          {translatedData.previous}
        </button>
      </div>
    </form>
  );
};

export default Step4;
