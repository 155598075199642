import React from "react";
import { Carousel } from "react-responsive-carousel";
import { useSelector } from "react-redux";
import { imageURL } from "../../redux/Api";
import "./Services.scss";

const Services = () => {
  const { servicesInfo } = useSelector((state) => state.services);

  return (
    <div className="about flex-row" id="services">
      <div className="carousal-preview">
        {servicesInfo.length !== 0 && (
          <Carousel
            infiniteLoop
            autoFocus
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            showArrows={false}
            stopOnHover={false}
            transitionTime={300}
            autoPlay
            axis="horizontal"
          >
            {servicesInfo?.map((service, index) => (
              <img
                key={index}
                src={imageURL + service.image.path}
                alt={`service ${index}`}
              />
            ))}
          </Carousel>
        )}
      </div>

      <div className="services flex-column">
        {servicesInfo.map((service, index) => (
          <div key={index} className="service">
            <h2 className="title">{service.name}</h2>
            <p className="desc">{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
