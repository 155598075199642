import { configureStore } from '@reduxjs/toolkit';
import ProfileReducer from './reducers/ProfileReducer';
import ServicesReducer from './reducers/ServicesReducer';
import GalleryReducer from './reducers/GalleryReducer';
import RequestReducer from './reducers/RequestReducer';

const store = configureStore({
    reducer: {
        profile: ProfileReducer,
        services: ServicesReducer,
        gallery: GalleryReducer,
        requests: RequestReducer,
    }
});
export default store