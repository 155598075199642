// export const baseURL = 'https://cp.zayna.mr-appss.com/back-end/public/index.php/api/';
export const baseURL =
  "https://advertisements.it.mr-appss.com/public/index.php/api/";

// export const imageURL = 'https://cp.zayna.mr-appss.com/back-end/public';
export const imageURL = "https://advertisements.it.mr-appss.com/public";

export const ShowProfileAPI = `v1/client/profile`;

//Services
export const GetServicesAPI = `v1/client/services`;

//Gellery
export const GetPostsAPI = `v1/client/galleries`;
export const ShowPostAPI = `v1/client/galleries/`;

export const PostRequestAPI = `v1/client/requests/send`;
