import React, { useContext, useState } from "react";
import "./StepStyle.scss";
import { LangContext } from "../../../context/LanguageProvider";
import { translations } from "../translate";
import { langType } from "../../../utils/Formatters";

const Step1 = ({ updateFormData, formData = {}, nextStep }) => {
  const { lang } = useContext(LangContext);
  const translatedData = translations[lang];

  const [localFormData, setLocalFormData] = useState({
    fullName: formData.fullName,
    email: formData.email,
    phone: formData.phone,
    address: formData.address,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalFormData({ ...localFormData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateFormData(localFormData);
    nextStep();
  };

  return (
    <form onSubmit={handleSubmit} className="step-form">
      <div>
        <label>{translatedData.fullName}</label>
        <input
          className={`${langType(localFormData.fullName[0])}`}
          type="text"
          name="fullName"
          value={localFormData.fullName}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label>{translatedData.email}</label>
        <input
          className={`${langType(localFormData.email[0])}`}
          type="email"
          name="email"
          value={localFormData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label>{translatedData.whatsappNumber}</label>
        <input
          className={`${langType(localFormData.phone[0])}`}
          type="tel"
          name="phone"
          value={localFormData.phone}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label>{translatedData.location}</label>
        <input
          className={`${langType(localFormData.address[0])}`}
          type="text"
          name="address"
          value={localFormData.address}
          onChange={handleChange}
          required
        />
      </div>
      <div className="actions">
        <button type="submit">{translatedData.next}</button>
      </div>
    </form>
  );
};

export default Step1;
