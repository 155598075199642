export function formatNumberWithAbbreviations(number) {
    if (number >= 1000000) {
        return (number / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (number >= 1000) {
        return (number / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return number.toString();
}

export const langType = (text) => {
    const arabicPattern = /[\u0600-\u06FF\u0750-\u077F]/;
    const englishPattern = /^[a-zA-Z0-9\s]+[\s]?$/;
    if (arabicPattern.test(text)) {
        return "ar"
    }
    if (englishPattern.test(text)) {
        return "en"
    }
    return
};