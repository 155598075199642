export const translations = {
    en: [
        "Arabic",
        "Home",
        "Services",
        "My Work (Gallery)",
        "Request Service",
        "About Me"
    ]
    ,
    ar: [
        "إنكليزي",
        "رئيسي",
        "خدمات",
        "معرض أعمالي",
        "طلب خدمة",
        "حول"
    ]
};
