import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetPostsAPI, ShowPostAPI, } from "../Api";
import axiosInstance, { errorMessage } from "../constant";


export const GetPostsAction = createAsyncThunk("admin/gallery/get", async (_, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.get(GetPostsAPI);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const ShowPostAction = createAsyncThunk("admin/gallery/show", async (id, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.get(ShowPostAPI + id);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});
